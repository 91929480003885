<template>
  <b-container class="container-rda siderbar" style="max-width: 100%">
    <b-row class="height">
      <sidebar-admin />
      <b-col class="content">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h2 class="purple bold">Usuários</h2>
          </b-col>
          <b-col class="text-right">
            <b-button
              class="btn large blue"
              :to="{
                name: 'novosusuario',
              }"
              >Novo usuário</b-button
            >
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Função">
              <b-form-select
                class=""
                size="sm"
                v-model="filters.role"
                :options="roles"
                @change="updateFilter"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col align="end">
            <b-form-group label="Buscar colaborador">
              <b-input-group-prepend>
                <b-form-input
                  type="search"
                  size="sm"
                  placeholder="Digite aqui"
                  v-model="filters.name"
                  v-debounce:1s="updateFilter"
                />
              </b-input-group-prepend>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row align="center" v-if="loading">
          <b-col class="mt-5">
            <b-spinner variant="success" label="Carregando..."></b-spinner>
          </b-col>
        </b-row>

        <b-row class="mt-3" v-if="!loading">
          <b-col>
            <b-container fluid>
              <b-row class="header-list-teacher" v-if="users.length > 0">
                <b-col md="3" align="center">
                  <p class="bold">Nome</p>
                </b-col>
                <b-col md="3" align="center">
                  <p class="bold">Função</p>
                </b-col>
                <b-col md="2" align="center">
                  <p class="bold">Editar senha</p>
                </b-col>

                <b-col md="2" align="center">
                  <p class="bold">Editar</p>
                </b-col>
                <b-col md="2" align="center">
                  <p class="bold">Excluir</p>
                </b-col>
              </b-row>
            </b-container>

            <b-container fluid>
              <b-row
                align-v="center"
                class="list-teacher"
                v-for="item in users"
                v-bind:key="item.id"
              >
                <b-col md="3" align="center">
                  <p>{{ item.name }}</p>
                </b-col>
                <b-col md="3" align="center">
                  <p>{{ item.Roles[0] | roles }}</p>
                </b-col>
                <b-col md="2" align="center">
                  <a
                    @click="() => setUserPassword(item)"
                    v-b-modal.edit-password
                  >
                    <LockIcon />
                  </a>
                </b-col>
                <b-col md="2" align="center">
                  <router-link
                    :to="{
                      name: 'perfil-usuario',
                      params: {
                        id: item.id,
                      },
                    }"
                  >
                    <EditIcon />
                  </router-link>
                </b-col>
                <b-col md="2" align="center">
                  <a @click="() => DeleteUser(item.id)">
                    <DeleteIcon />
                  </a>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <UserEditPassword :key="userToPassword" :userToEdit="userToPassword" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import HttpRda from "../../../http-rda";
import EditIcon from "@/components/svg/EditIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import LockIcon from "@/components/svg/LockIcon.vue";
import UserEditPassword from "@/components/modals/UserEditPassword.vue";
import SidebarAdmin from "../../../components/SidebarAdmin.vue";

export default {
  components: {
    DeleteIcon,
    LockIcon,
    EditIcon,
    UserEditPassword,
    SidebarAdmin,
  },

  data() {
    return {
      name: "usuarios",
      loading: false,
      filters: {
        role: null,
        name: null,
      },
      userToPassword: {},
      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Usuários",
          href: "/secretaria/usuarios",
        },
      ],
      users: [],
      roles: [
        {
          value: null,
          text: "Selecione",
        },
        {
          value: 7,
          text: "Coordenação",
        },
        {
          value: 8,
          text: "Secretaria",
        },
        {
          value: 9,
          text: "Portaria",
        },
        {
          value: 10,
          text: "QrCode",
        },
        {
          value: 13,
          text: "Gestor de Faltas e Presenças",
        },
      ],
    };
  },

  methods: {
    DeleteUser(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await HttpRda.delete(`/collaborator/${id}`)
              .then(() => {
                this.$swal.fire({
                  title: "Sucesso!",
                  text: "Usuário excluido com sucesso!",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.GetUser();
              })
              .catch(() => {
                this.$swal.fire({
                  title: "Erro!",
                  text: "Não foi possível excluir o usuário!",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          }
        });
    },

    setUserPassword(item) {
      this.userToPassword = item;
    },

    updateFilter() {
      let filterFormatted = {};

      if (this.filters.role) {
        filterFormatted.role = this.filters.role;
      }

      if (this.filters.name && this.filters.name !== "") {
        filterFormatted.name = this.filters.name;
      }

      this.GetUser(filterFormatted);
    },

    async GetUser(filters = null) {
      this.loading = true;

      try {
        await HttpRda.get("/collaborator", { params: filters || {} }).then(
          (response) => {
            this.users = response.data.users;
            this.loading = false;
          }
        );
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },

  created() {
    this.GetUser();
  },

  filters: {
    roles: function (role) {
      if (!role.id) return "";

      switch (role.name) {
        case "coordinator":
          return "Coordenação";
        case "secretary":
          return "Secretaria";
        case "concierge":
          return "Portaria";
        case "qrcode":
          return "QrCode";
        case "attendance_manager":
          return "Gestor de Faltas e Presenças";
      }

      return "";
    },
  },
};
</script>

<style></style>
